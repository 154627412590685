<template>
  <ApexChart class="max-h-0" type="donut" :series="series" :options="options" />
</template>

<script lang="ts" setup>
import { ApexOptions } from 'apexcharts'
import { computed, defineComponent } from 'vue'
import ApexChart from 'vue3-apexcharts'

type Props = { responseCounts: Record<string, number> }
const props = defineProps<Props>()

const series = computed(() => Object.values(props.responseCounts))
const labels = computed(() => Object.keys(props.responseCounts))
const totalCount = computed(() => series.value.reduce((sum, count) => sum + count, 0))

const options = computed<ApexOptions>(() => {
  return {
    labels: labels.value,
    dataLabels: {
      enabled: true,
      formatter: (val: number) => `${(val * totalCount.value) / 100} (${Math.round(val)} %)`,
      style: {
        fontSize: '14px',
      },
    },
    pie: {
      expandOnClick: true,
    },
    theme: {
      palette: 'palette3',
    },
    stroke: {
      colors: ['rgb(255, 255, 255)'],
    },
    legend: {
      show: true,
      labels: {
        colors: ['#ffffff'],
      },
      width: 150,
    },
  }
})
</script>

<style lang="scss">
.apexcharts-legend-text {
  color: white !important;
}
</style>
