<template>
  <div class="h-full">
    <div class="flex justify-between items-center">
      <h1 class="font-semibold text-2xl sm:text-4xl">Valgresultater</h1>
      <div class="flex justify-end items-center space-x-2">
        <label for="showDonutCharts">Vis donut-graf</label>
        <div class="w-6 h-6 rounded-md overflow-hidden">
          <input class="w-full h-full" type="checkbox" name="showDonutCharts" v-model="showDonutCharts" />
        </div>
      </div>
    </div>
    <h2 class="font-semibold text-lg sm:text-2xl mt-2">"{{ election.title }}"</h2>

    <hr class="border-gray-500 my-4" />

    <div class="flex flex-col space-y-4">
      <div
        v-for="(question, idx) in election.questions"
        :key="question.id"
        class="border border-gray-500 bg-gray-700 rounded p-4"
      >
        <p class="text-sm text-gray-300 italic">Spørsmål #{{ idx + 1 }}</p>
        <h4
          class="font-semibold sm:text-lg border-b-2 border-dotted border-gray-400 inline-block cursor-help"
          :title="question.description"
        >
          {{ question.label }}
        </h4>

        <div v-if="question.responses.length == 0" class="mt-2 italic text-gray-300">
          Ingen respons på dette spørsmålet enda
        </div>

        <div v-else-if="showDonutCharts">
          <PieChart :responseCounts="getResponseCountObject(question.responses)" />
        </div>

        <div v-else class="flex flex-col items-start space-y-1 mt-2">
          <span
            v-for="(response, idx) of question.responses"
            :key="idx"
            class="p-1 pt-0 bg-gray-900 font-semibold border border-gray-600 rounded"
          >
            {{ response }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { BASE_SERVER_URL, BASE_WEBSITE_URL, getQueryParameter } from '../utils'
import PieChart from '../components/PieChart.vue'
import { onMounted, ref } from 'vue'
import { ElectionWithResponses } from '../types'

const election = ref<ElectionWithResponses>({
  title: 'Laster...',
  questions: [],
})
const loading = ref(false)
const showDonutCharts = ref(false)

async function fetchResults() {
  const nonce = getQueryParameter('nonce')

  const url = `${BASE_SERVER_URL}/elections/${nonce}/results`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
    mode: 'cors',
  })
  if (response.ok) {
    const body = await response.json()
    election.value = body
    loading.value = false
  } else {
    const body = await response.json()
    if (body.error == 'nonce.invalid') {
      alert('Ugyldig passord.')
      window.location.href = BASE_WEBSITE_URL
    } else {
      alert('Noe gikk galt.')
    }
  }
}

function getResponseCountObject(responses: string[]): Record<string, number> {
  return responses.reduce((responseCounter, response) => {
    responseCounter[response] = (responseCounter[response] ?? 0) + 1
    return responseCounter
  }, {})
}

onMounted(fetchResults)
</script>

<style lang="scss">
:root {
  accent-color: #a78949;
}
</style>
