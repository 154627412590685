<template>
  <div class="flex flex-col">
    <InputHeader :label="label" :description="description" :question-type="type" />
    <select
      v-model="computedValue"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled"
      class="mt-2 border border-gray-500 rounded text-black"
      :class="small ? 'p-1' : 'p-2'"
    >
      <option value="" selected hidden>{{ placeholder || '' }}</option>
      <option v-for="option in options" :value="option.id" :key="option.id">
        {{ option.text }}
      </option>
    </select>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { SelectOption } from '../../types'
import InputHeader from './InputHeader.vue'

type Props = {
  modelValue: string | undefined
  options: SelectOption[]
  label?: string
  description?: string
  placeholder?: string
  name?: string
  small?: boolean
  disabled?: boolean
  type?: string
}
const props = defineProps<Props>()
const emit = defineEmits<{
  'update:modelValue': [value: string | undefined]
}>()

const computedValue = computed({
  get: () => props.modelValue,
  set: (value: string | undefined) => emit('update:modelValue', value ?? undefined),
})
</script>

<style lang="scss" scoped>
select > option {
  @apply p-2;
}
</style>
