<template>
  <div>
    <h1 class="text-2xl sm:text-4xl font-semibold">Nytt valg</h1>

    <div class="flex flex-col mt-4">
      <TextField
        v-model="email"
        name="email"
        label="Din epost:"
        description="Du vil motta en epost med en lenke for å se valgresultatene"
        placeholder="donald@duck.com"
      />
    </div>

    <div class="flex flex-col mt-4">
      <TextArea
        v-model="emails"
        name="emails"
        label="Mottakere:"
        description="Mottakerliste av eposter. Separert med mellomrom, linjeskift eller semikolon."
        :placeholder="'donald@duck.com;\ndolly@duck.com anton@duck.com\nskrue@mcduck.com'"
      />
    </div>

    <div class="flex flex-col mt-4">
      <TextField
        v-model="title"
        name="title"
        label="Tittel:"
        description="Valgets tittel (vil stå øverst på siden)."
        placeholder="Kjapt Valg"
      />
    </div>

    <hr class="border-gray-500 my-4" />

    <div class="flex justify-between flex-wrap">
      <h2 class="text-xl sm:text-2xl font-semibold">Spørsmål</h2>
      <div class="flex justify-end space-x-2">
        <button
          v-for="questionType in questionTypes"
          :key="questionType.type"
          @click="addQuestion(questionType.type)"
          class="border border-gray-600 rounded bg-gray-800 hover:bg-gray-700 font-bold place-self-end px-2 py-1"
          title="Legg til spørsmål"
        >
          &plus; {{ questionType.text }}
        </button>
      </div>
    </div>

    <div class="flex flex-col space-y-4 mt-2">
      <div
        v-for="(question, idx) in questions"
        :key="question.id"
        class="p-4 pt-2 rounded border border-gray-500 bg-gray-700 w-full flex flex-col space-y-2"
      >
        <div class="flex justify-between items-center">
          <h3 class="text-lg sm:text-xl font-semibold">Spørsmål #{{ idx + 1 }}</h3>
          <button class="text-lg text-center h-7 w-7 -mr-1" title="Slett spørsmål" @click="deleteQuestion(question.id)">
            &times;
          </button>
        </div>

        <TextField
          v-model="question.label"
          name="label"
          label="Overskrift"
          description="Den store skriften (som der det står overskrift)."
        />
        <TextField
          v-model="question.description"
          name="description"
          label="Beskrivelse"
          description="Den lille skriften (som her!)."
        />

        <div
          v-if="question.type === 'select' || question.type === 'multiselect'"
          class="flex flex-col p-4 bg-gray-900 rounded border border-gray-500"
        >
          <div class="flex justify-between items-center">
            <h4 class="text sm:text-lg font-semibold">Alternativer</h4>
            <NumberField
              v-if="question.type === 'multiselect'"
              v-model="question.selectionLimit"
              placeholder="Maks valg"
              name="selectionLimit"
              :min="1"
              :max="question?.options?.length"
              small
              class="w-28"
            />
            <button
              @click="addOption(question.id)"
              class="border border-gray-600 rounded bg-gray-800 hover:bg-gray-700 font-bold px-3 py-1"
              title="Legg til alternativ"
            >
              &plus; Alternativ
            </button>
          </div>

          <div v-for="option in question.options" :key="option.id" class="flex justify-between items-center mt-2">
            <TextField v-model="option.text" class="flex-grow" :name="option.id" small />
            <button class="text-lg h-7 w-7 ml-4" title="Slett spørsmål" @click="deleteOption(question.id, option.id)">
              &times;
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col mt-4">
      <button
        @click="preview = true"
        class="border border-gray-600 rounded bg-gray-800 hover:bg-gray-700 font-bold text-lg px-3 py-1"
        title="Åpne forhåndsvisning"
      >
        Forhåndsvis
      </button>
    </div>

    <ModalDialog v-if="preview" @close="preview = false">
      <h2 class="text-xl sm:text-2xl font-semibold">Forhåndsvisning:</h2>
      <hr class="border-gray-500 my-4" />
      <VoteForm :election="election" preview />
      <hr class="border-gray-500 my-4" />

      <p class="text-gray-300 italic">
        Når du trykker på "&check; Send"-knappen, vil valget sendes ut. Alle mottakere vil motta en epost med en
        invitasjon til å delta, og du vil motta en epost med en invitasjon til å se resultatene.
      </p>

      <div class="flex justify-between mt-4">
        <button
          @click="preview = false"
          class="border border-gray-600 rounded bg-gray-800 hover:bg-gray-700 font-bold px-3 py-1"
          title="Legg til alternativ"
        >
          ❌ Avbryt
        </button>
        <button
          @click="submit"
          class="border border-gray-600 rounded bg-gray-800 hover:bg-gray-700 font-bold px-3 py-1"
          title="Legg til alternativ"
        >
          ✔ Send
        </button>
      </div>
    </ModalDialog>
  </div>
</template>

<script lang="ts" setup>
import VoteForm from './sub-views/VoteForm.vue'
import { BASE_SERVER_URL, BASE_WEBSITE_URL } from '../utils'
import { computed, ref } from 'vue'
import { MultiSelectQuestion, Question, QuestionType, SelectOption, SelectQuestion, TextQuestion } from '../types'
import NumberField from '../components/input/NumberField.vue'
import TextField from '../components/input/TextField.vue'
import TextArea from '../components/input/TextArea.vue'
import ModalDialog from '../components/ModalDialog.vue'

const questionTypes = [
  { type: 'select', text: 'Enkeltvalg' },
  { type: 'multiselect', text: 'Flervalg' },
  { type: 'text', text: 'Tekstsvar' },
] as { type: QuestionType; text: string }[]

const preview = ref(false)
const title = ref('')
const email = ref('')
const emails = ref('')
const questions = ref<Question[]>([])

const emailList = computed(
  () =>
    emails.value
      .split(/(\s*;\s*)|(\s+)/g)
      .filter((email) => !!email) // filter out falsy values (null, undefined)
      .map((email) => email.trim()) // remove stuck whitespace
      .filter((email) => email.length > 0) // filter out empty strings
      .filter((email) => email !== ';') // filter out semicolon strings)
)

const election = computed(() => ({ title: title.value, questions: questions.value }))

function generateId() {
  const dec2hex = (dec) => dec.toString(16).padStart(2, '0')
  const arr = new Uint8Array(10)
  window.crypto.getRandomValues(arr)
  return Array.from(arr, dec2hex).join('')
}

function addQuestion(type: QuestionType) {
  switch (type) {
    case 'select':
      return questions.value.push({
        id: generateId(),
        type,
        label: '',
        description: '',
        options: [],
      } as SelectQuestion)
    case 'multiselect':
      return questions.value.push({
        id: generateId(),
        type,
        label: '',
        description: '',
        options: [],
        selectionLimit: undefined,
      } as MultiSelectQuestion)
    case 'text':
      return questions.value.push({
        id: generateId(),
        type,
        label: '',
        description: '',
      } as TextQuestion)
  }
}

function deleteQuestion(questionId: string) {
  questions.value = questions.value.filter((question) => question.id !== questionId)
}

function addOption(questionId: string) {
  const option: SelectOption = {
    id: generateId(),
    text: '',
  }
  const question = questions.value.find((question) => question.id === questionId)
  if (question.type !== 'text' && Array.isArray(question.options)) {
    question.options.push(option)
  }
}

function deleteOption(questionId: string, optionId: string) {
  const question = questions.value.find((question) => question.id === questionId)
  if (question.type !== 'text' && Array.isArray(question.options)) {
    question.options = question.options.filter((option) => option.id !== optionId)
  }
}

async function submit() {
  // https://stackoverflow.com/a/9204568/
  if (!/\S+@\S+\.\S+/.test(email.value)) return alert('Du må oppgi en gyldig e-post-adresse for administrator.')
  if (emailList.value.length < 1) return alert('Du må oppgi minst én gyldig mottaker.')
  if (!title.value) return alert('Du må gi valget en tittel.')
  const url = `${BASE_SERVER_URL}/elections`
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      title: title.value,
      email: email.value,
      emails: emailList.value,
      questions: questions.value,
    }),
  })

  if (response.ok) {
    alert(
      'Takk for at du opprettet et valg hos Kjapt Valg! ' +
        'Velgerne mottar straks en invitasjonsepost, ' +
        'og du vil motta en epost med invitasjon til å se resultatene.'
    )
    window.location.href = BASE_WEBSITE_URL
  } else {
    alert('Noe gikk galt.')
  }
}
</script>

<style></style>
