<template>
  <div>
    <h1 class="text-2xl sm:text-4xl font-semibold">Velkommen til Kjapt Valg!</h1>
    <p class="mt-4">Skal dere avholde et kjapt valg i borettslaget? Kanskje på årsmøtet i menigheten? Kanskje dere skal velge nytt elevråd? Her er det kjapt å opprette et valg!</p>


    <div class="flex flex-col mt-8">
      <router-link 
        to="/nytt-valg"
        class="border border-gray-600 rounded bg-gray-800 hover:bg-gray-700 font-bold px-4 py-2 text-center"
        title="Klikk her for å opprette et valg!"
      >
        Opprett et nytt valg
      </router-link> 
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style>
</style>