<template>
  <div class="flex flex-col">
    <InputHeader :label="label" :description="description" :question-type="type" />
    <input
      v-model="computedValue"
      type="text"
      :name="name"
      :placeholder="placeholder"
      class="border border-gray-500 rounded text-black"
      :class="small ? 'p-1' : 'p-2'"
      :disabled="disabled"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import InputHeader from './InputHeader.vue'

type Props = {
  modelValue: string
  label?: string
  description?: string
  placeholder?: string
  name?: string
  small?: boolean
  disabled?: boolean
  type?: string
}
const props = defineProps<Props>()
const emit = defineEmits<{
  'update:modelValue': [value: string]
}>()

const computedValue = computed({
  get: () => props.modelValue,
  set: (value: string) => emit('update:modelValue', value ?? ''),
})
</script>

<style lang="scss"></style>
