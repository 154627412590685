<template>
  <div class="flex flex-col space-y-4">
    <h1 class="font-semibold" :class="preview ? 'text-lg sm:text-xl' : 'text-2xl sm:text-4xl'">
      {{ election.title || 'Kjapt Valg' }}
    </h1>
    <div class="flex flex-col space-y-4">
      <div class="flex flex-col" v-for="question in questionsWithResponses" :key="question.id">
        <TextField
          v-if="question.type === 'text'"
          :modelValue="getTextValue(question.id)"
          @update:modelValue="(newVal) => setTextValue(question.id, newVal)"
          :name="question.id"
          :label="question.label"
          :description="question.description"
        />
        <SingleSelect
          v-if="question.type === 'select'"
          :modelValue="getTextValue(question.id)"
          @update:modelValue="(newVal) => setTextValue(question.id, newVal)"
          :options="question.options"
          :name="question.id"
          :label="question.label"
          :description="question.description"
        />
        <MultiSelect
          v-if="question.type === 'multiselect'"
          :modelValue="getArrayValue(question.id)"
          @update:modelValue="(newVal) => setArrayValue(question.id, newVal)"
          :options="question.options"
          :selectionLimit="question.selectionLimit"
          :name="question.id"
          :label="question.label"
          :description="question.description"
        />
      </div>
    </div>
    <button v-if="!loading" class="bg-blue-600 p-2 text-xl rounded" type="submit" @click="submit">STEM</button>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, unref, watch } from 'vue'
import { Election, MultiSelectQuestion, Question, QuestionResponse, WithResponse } from '../../types'
import { BASE_SERVER_URL, getQueryParameter } from '../../utils'
import TextField from '../../components/input/TextField.vue'
import SingleSelect from '../../components/input/SingleSelect.vue'
import MultiSelect from '../../components/input/MultiSelect.vue'

type Props = {
  election: Election
  preview?: boolean
  loading?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  preview: false,
  loading: false,
})

const emit = defineEmits<{ voted: [] }>()

function getQuestionsWithResponses() {
  return props.election.questions.map((question) => ({
    ...question,
    response: question.type === 'multiselect' ? [] : undefined,
  }))
}
const questionsWithResponses = ref<WithResponse<Question>[]>(getQuestionsWithResponses())

watch(
  () => props.election,
  () => {
    questionsWithResponses.value = getQuestionsWithResponses()
  },
  { deep: true }
)

function getTextValue(id: string) {
  return questionsWithResponses.value.find((q) => q.id === id).response as string
}
function setTextValue(id: string, value: string) {
  questionsWithResponses.value.find((q) => q.id === id).response = value
}
function getArrayValue(id: string) {
  return questionsWithResponses.value.find((q) => q.id === id).response as string[]
}
function setArrayValue(id: string, value: string[]) {
  questionsWithResponses.value.find((q) => q.id === id).response = value
}

const responses = computed<Record<string, QuestionResponse>>(() =>
  Object.fromEntries(questionsWithResponses.value.map((q) => [q.id, q.response]))
)

async function submit() {
  if (props.preview) {
    return alert('Du kan ikke stemme i forhåndsvisningen! Om du er fornøyd med skjemaet: Trykk på "✔ Send" da vel!')
  }
  const nonce = getQueryParameter('nonce')

  const url = `${BASE_SERVER_URL}/voter/${nonce}/vote`
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      response: responses.value,
    }),
  })
  if (response.ok) {
    emit('voted')
  } else {
    const body = await response.json()
    if (body.error == 'nonce.invalid') {
      alert('Ugyldig passord.')
    } else {
      alert('Noe gikk galt.')
    }
  }
}
</script>

<style></style>
