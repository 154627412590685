import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Vote from '../views/Vote.vue'
import NewElection from '../views/NewElection.vue'
import Results from '../views/Results.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/stem',
    name: 'Stem',
    component: Vote,
  },
  {
    path: '/nytt-valg',
    name: 'Nytt valg',
    component: NewElection,
  },
  {
    path: '/resultater',
    name: 'Valgresultater',
    component: Results,
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

export default router
