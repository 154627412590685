<template>
  <div class="flex flex-col">
    <InputHeader :label="label" :description="description" :question-type="type" :meta="selectionLimitText" />
    <select
      v-model="computedValue"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled"
      multiple
      class="mt-2 border border-gray-500 rounded text-black"
      :class="small ? 'p-1' : 'p-2'"
    >
      <option value="" selected hidden>{{ placeholder || '' }}</option>
      <option v-for="option in options" :value="option.id" :key="option.id">
        {{ option.text }}
      </option>
    </select>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { SelectOption } from '../../types'
import InputHeader from './InputHeader.vue'

type Props = {
  modelValue: string[]
  options: SelectOption[]
  label?: string
  description?: string
  placeholder?: string
  name?: string
  small?: boolean
  disabled?: boolean
  selectionLimit?: number
  type?: string
}
const props = defineProps<Props>()
const emit = defineEmits<{
  'update:modelValue': [newValue: string[]]
}>()

const computedValue = computed({
  get: () => props.modelValue,
  set: (value: string[]) => {
    if (props.selectionLimit && value.length > props.selectionLimit) {
      value = value.slice(0, props.selectionLimit)
      alert(`Maks ${props.selectionLimit} valg på dette spørsmålet!`)
    }
    emit('update:modelValue', value)
  },
})

const selectionLimitText = computed(() => {
  if (props.selectionLimit) {
    return `Maks ${props.selectionLimit} valg`
  }
})
</script>

<style lang="scss" scoped>
select > option {
  @apply p-2;
}
</style>
