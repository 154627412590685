<template>
  <div class="h-full">
    <VoteForm v-if="!hasVoted" @voted="hasVoted = true" :election="election" :loading="loading" />
    <PostVote v-else />
  </div>
</template>

<script lang="ts" setup>
import PostVote from './sub-views/PostVote.vue'
import VoteForm from './sub-views/VoteForm.vue'

import { BASE_SERVER_URL, BASE_WEBSITE_URL, getQueryParameter } from '../utils.js'
import { onMounted, ref } from 'vue'
import { Election } from '../types'

const hasVoted = ref(false)
const loading = ref(true)
const election = ref<Election>({
  title: 'Laster...',
  questions: [],
})

async function fetchElection() {
  const nonce = getQueryParameter('nonce')

  const url = `${BASE_SERVER_URL}/voter/${nonce}/election`
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
    mode: 'cors',
  })
  if (response.ok) {
    const body = await response.json()
    election.value = body
    loading.value = false
  } else {
    const body = await response.json()
    if (body.error == 'nonce.invalid') {
      alert('Ugyldig passord.')
      window.location.href = BASE_WEBSITE_URL
    } else {
      alert('Noe gikk galt.')
    }
  }
}

onMounted(fetchElection)
</script>

<style></style>
