<template>
  <teleport to="body">
    <div class="fixed inset-0 p-8 bg-black bg-opacity-75 flex justify-center items-center" @click.self="emit('close')">
      <div class="p-4 border border-gray-500 rounded bg-gray-800 max-w-lg max-h-full overflow-y-auto">
        <slot />
      </div>
    </div>
  </teleport>
</template>

<script lang="ts" setup>
const emit = defineEmits<{ close: [] }>()
</script>

<style></style>
